import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import EmbedVideo from '../EmbedVideo'

const About = () => {
	const data = useStaticQuery(graphql`{
  content: file(relativePath: {eq: "about.md"}) {
    childMarkdownRemark {
      html
      frontmatter {
        id
        title
      }
    }
  }
  image: file(relativePath: {eq: "general/Boswell-Festival-whos-who.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`)

	return (
		<section id="/about" className="p-4 md:p-12">
			<div className="markdown-content mb-16" dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }}></div>
			<GatsbyImage
				image={data.image.childImageSharp.gatsbyImageData}
				alt={``}
				className={`shadow-md mb-16 max-w-md mx-auto`} />
			<h3 className={`text-2xl font-heading mb-2`}>2021 Festival Highlights</h3>
			<EmbedVideo src={`https://www.youtube-nocookie.com/embed/UqHrEISYT4w`} title={`2021 Festival Highlights`} />
		</section>
    );
}

export default About
