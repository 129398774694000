import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import About from '../components/Page/About'
import WhosWho from '../components/Page/WhosWho'
import BannerImage from '../components/Page/BannerImage'

const aboutPage = ({data}) => {
	return (
		<Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2">
					<About />
				</div>
				<div className="md:w-1/2">
					<WhosWho />
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-001.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default aboutPage
